import Fab from '@material-ui/core/Fab';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import type { Theme } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { Link as GatsbyLink } from 'gatsby';

import TextGradient from './TextGradient';

const useStyles = makeStyles<Theme>(
  ({ breakpoints, spacing, palette, transitions, typography }) => ({
    root: {
      background: `linear-gradient(15deg, ${palette.background.default}, ${palette.secondary.dark})`,
      color: palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      opacity: 0.85,
      padding: spacing(2),
      textDecoration: 'none',
      transition: `opacity ${transitions.duration.short}ms`,

      '@media (hover: hover)': {
        '&:hover': {
          opacity: 1,
        },
      },

      [breakpoints.up('md')]: {
        padding: spacing(3),
      },
    },

    excerpt: {
      marginBottom: spacing(2),
      flexGrow: 1,
    },
    date: {
      fontSize: 12,
      margin: spacing(1, 2, 1, 0),
    },
    continue: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: spacing(1, 0),
    },
    continueLabel: {
      fontSize: typography.caption.fontSize,
      marginRight: spacing(1),
    },
  })
);

interface BlogPostProps {
  date: string;
  excerpt: string;
  href: string;
  title: string;
  dateShow: string;
}

const BlogPostCard: React.FC<BlogPostProps> = ({
  date,
  excerpt,
  href,
  title,
  dateShow,
}) => {
  const classes = useStyles();

  return (
    <GatsbyLink className={classes.root} to={href}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Typography className={classes.excerpt} variant="caption" component="p">
        {excerpt}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <time dateTime={date} className={classes.date}>
          {dateShow}
        </time>
        <div role="button" className={classes.continue}>
          <TextGradient className={classes.continueLabel}>
            Read more
          </TextGradient>
          <Fab
            aria-hidden="true"
            color="secondary"
            component="span"
            size="small"
          >
            <ArrowForward fontSize="small" />
          </Fab>
        </div>
      </Box>
    </GatsbyLink>
  );
};

export default BlogPostCard;
