import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';
import { graphql } from 'gatsby';

import BlogPostCard from '../components/BlogPostCard';
import Footer from '../components/Footer';
import LazyStarsCanvas from '../components/Canvas/StarsCanvas/LazyStarsCanvas';
import Seo from '../components/Seo';
import SubscribeForm from '../components/SubscribeForm';
import TextGradient from '../components/TextGradient';
import type { GatsbyPageProps } from '../types';
import CtaBox from '../components/CtaBox';

const useStyles = makeStyles<Theme>(({ breakpoints, spacing }) => ({
  headline: {
    marginBottom: spacing(4),
  },
}));

type Data = any;

const BlogPage: React.FC<GatsbyPageProps<Data>> = ({ data }) => {
  const classes = useStyles();
  const posts = data?.allMdx?.edges;
  const { authorLinks, email } = data?.site?.siteMetadata;

  return (
    <>
      <Seo
        description="Front End technical articles on webman.pro blog. ✔ Interesting posts about web development."
        path="/blog/"
        title="Front End Blog - webman.pro"
      />
      <Box mt={20} />
      <Container>
        <Grid container spacing={4}>
          <Grid item md={10}>
            <Typography
              className={classes.headline}
              component="h1"
              gutterBottom
              variant="h2"
            >
              Technical <TextGradient>Front</TextGradient>{' '}
              <TextGradient>End</TextGradient> blog.{' '}
              <TextGradient>Hot</TextGradient> topics only.
            </Typography>
          </Grid>
          {posts.map(({ node: post }) => (
            <Grid item xs={12} sm={6} lg={4} key={post.id}>
              <BlogPostCard
                excerpt={post.excerpt}
                href={post.fields.slug}
                title={post.frontmatter.h1}
                date={post.frontmatter.dateModified}
                dateShow={post.frontmatter.dateModifiedHuman}
              />
            </Grid>
          ))}
        </Grid>
        <Box mt={20} mb={10}>
          <CtaBox>
            <SubscribeForm />
          </CtaBox>
        </Box>
      </Container>
      <Footer position="static" email={email} authorLinks={authorLinks} />
      <LazyStarsCanvas />
    </>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogPage {
    site {
      siteMetadata {
        authorLinks {
          href
          label
        }
        email
      }
    }
    allMdx(sort: { order: DESC, fields: frontmatter___dateCreated }) {
      edges {
        node {
          id
          fileAbsolutePath
          excerpt
          frontmatter {
            h1
            dateModified
            dateModifiedHuman: dateModified(formatString: "MMMM Do, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
